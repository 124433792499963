<template>
  <side-nav v-if="activity.id && isTutor" v-model="expandedTutorSideNav">
    <div v-if="$i18n.locale == 'es'" class="activity-information">
      <div class="activity-information__item">
        <p class="activity-information__title">{{$t('activityshow.nivel')}}</p>
        <p class="activity-information__description">{{activity.grade.name}}</p>
      </div>
      <div class="activity-information__item">
        <p class="activity-information__title">{{$t('activityshow.eje')}}</p>
        <p class="activity-information__description">{{activity.subject.name}}</p>
      </div>
      <div class="activity-information__item">
        <p class="activity-information__title">{{$t('activityshow.asignatura')}}</p>
        <p class="activity-information__description">{{activity.curricular_axis.name}}</p>
      </div>
      <div v-if="activity.activity_event.end_date"  class="activity-information__item">
        <p class="activity-information__title">{{$t('activityshow.activacion')}}</p>
        <p class="activity-information__title">{{$t('activityshow.desde')}}</p>
        <p class="activity-information__description">{{activity.activity_event.start_date}}</p>
        <p class="activity-information__title">{{$t('activityshow.hasta')}}</p>
        <p class="activity-information__description">{{activity.activity_event.end_date}}</p>
       </div>
       <div v-else class="activity-information__item">
          <p class="activity-information__title">{{$t('activityshow.activacion')}}</p>
          <p class="activity-information__description">{{$t('activityshow.ilimitada')}}</p>
        </div>
        <div v-if="activity.learning_objectives" class="activity-information__item">
        <p class="activity-information__title">{{$t('activityshow.objetivosDeAprendizaje')}}</p>
        <p class="activity-information__description" v-html="activity.learning_objectives"></p>
      </div>
      <div v-if="activity.socioemotional_development_objective" class="activity-information__item">
        <p class="activity-information__title">{{$t('activityshow.objetivoDeDesarrolloSocioemocional')}}</p>
        <p class="activity-information__description">{{activity.socioemotional_development_objective}}</p>
      </div>
      <div class="activity-information__item">
        <p class="activity-information__title">{{$t('activityshow.por')}}</p>
        <p class="activity-information__description">{{activityAuthor}}</p>
      </div>
    </div>

    <div v-else-if="$i18n.locale == 'en'" class="activity-information">
      <div class="activity-information__item">
        <p class="activity-information__title">{{$t('activityshow.nivel')}}</p>
        <p class="activity-information__description">{{activity.grade.name_en}}</p>
      </div>
      <div class="activity-information__item">
        <p class="activity-information__title">{{$t('activityshow.eje')}}</p>
        <p class="activity-information__description">{{activity.subject.name_en}}</p>
      </div>
      <div class="activity-information__item">
        <p class="activity-information__title">{{$t('activityshow.asignatura')}}</p>
        <p class="activity-information__description">{{activity.curricular_axis.name_en}}</p>
      </div>
       <div v-if="activity.activity_event.end_date"  class="activity-information__item">
        <p class="activity-information__title">{{$t('activityshow.activacion')}}</p>
        <p class="activity-information__title">{{$t('activityshow.desde')}}</p>
        <p class="activity-information__description">{{activity.activity_event.start_date}}</p>
        <p class="activity-information__title">{{$t('activityshow.hasta')}}</p>
        <p class="activity-information__description">{{activity.activity_event.end_date}}</p>
       </div>
       <div v-else class="activity-information__item">
          <p class="activity-information__title">{{$t('activityshow.activacion')}}</p>
          <p class="activity-information__description">{{$t('activityshow.ilimitada')}}</p>
       </div>
      <div v-if="activity.learning_objectives" class="activity-information__item">
        <p class="activity-information__title">{{$t('activityshow.objetivosDeAprendizaje')}}</p>
        <p class="activity-information__description" v-html="activity.learning_objectives"></p>
      </div>
      <div v-if="activity.socioemotional_development_objective" class="activity-information__item">
        <p class="activity-information__title">{{$t('activityshow.objetivoDeDesarrolloSocioemocional')}}</p>
        <p class="activity-information__description">{{activity.socioemotional_development_objective}}</p>
      </div>
      <div class="activity-information__item">
        <p class="activity-information__title">{{$t('activityshow.por')}}</p>
        <p class="activity-information__description">{{activityAuthor}}</p>
      </div>
    </div>
  </side-nav>

  <base-view-layout v-if="activity.id">
      <template #title>
      <div class="no-title-genially" v-if="activity.instruction.includes('view.genial.ly')" >{{activity.title}}</div>
      <div v-else>{{activity.title}}</div>
      </template>
    <!-- <template #subtitle>¡Hola! Gracias por participar, esperamos que lo pasen bien y que la actividad les ayude a entender más de matemáticas.</template> -->    
    <div class="activity" :class="{'activity-without-carrousel-manual':is_manual, 'without-carrousel-slide':with_slide}">
    <div v-if="with_slide" class="with-carrousel">
      <div class="main-activity-with-carrousel">
        <div class="content-with-carrousel">
          <section class="carrousel">
            <div v-if="expandedTutorSideNav " class="slide">
              <Carousel :items-to-show="1" >
                  <Slide v-for="(instruction, key) in aux2" :key="key" >
                    <div class="slide-content" v-html="instruction.name"></div>
                  </Slide>

                  <template #addons>
                    <Navigation />
                    <Pagination />
                  </template>
              </Carousel>
            </div>
            <div v-else-if="!expandedTutorSideNav " class="slide">
              <Carousel :items-to-show="1" >
                  <Slide v-for="(instruction, key) in aux2" :key="key" >
                    <div class="slide-content" v-html="instruction.name"></div>
                  </Slide>

                  <template #addons>
                    <Navigation />
                    <Pagination />
                  </template>
              </Carousel>
            </div>
          </section>
        </div>
      </div>
    </div>

    <div v-else class="without-carrousel" :class="{'without-carrousel-genially':is_genially}">
      <div class="genially" ref="div-genially" v-if="is_genially" >
        <div class="main-section-without-carrousel-genially">
          <section class="main-section">
            <div class="box box--sm box--no-shadow" v-html="activity.instruction"></div>
            <div v-if="dispositivo=='windows'" class="redomendations-desktop">{{$t('activityshow.fullscreendesktopone')}}<img src="@/assets/images/icons/fullscreen_desktop.svg">
            </div>

            <div v-if="dispositivo=='macintosh' " class="redomendations-desktop">{{$t('activityshow.fullscreendesktopone')}}<img src="@/assets/images/icons/fullscreen_desktop.svg">
            </div>

            <div v-if="dispositivo=='android'" class="redomendations-desktop">{{$t('activityshow.fullscreendesktopone')}}<img src="@/assets/images/icons/fullscreen_desktop.svg">
            </div>

            <div v-if="dispositivo=='ios'" class="redomendations-phone">
              {{$t('activityshow.fullscreenphone')}}<img align="center" src="@/assets/images/icons/fullscreen_phone.svg">
            </div>
          </section>
        </div>
      </div>

      <div v-else class="no-genially" >
        <div class="main-section-without-carrousel-genially">
          <section class="main-section" >
            <div class="box box--sm box--no-shadow" v-html="activity.instruction">
            </div>
          </section>
        </div>
      </div>
    </div>

    <div  v-if="isTutor">
      <br><br><br>
      <div :class="{'question-section-manual':is_manual, 'question-section-slide':with_slide}" align="center" id="contentOfTheOpenQuestionButton">
        <button class="btn btn--primary" type="submit" @click.prevent="newActivityCompleted" v-bind:class="{ disable: is_it_a_completed_activity }">{{$t('activityshow.activityCompletedButton')}}</button>
      </div>
      <br><br><br>
    </div>

    <div class="question-section" :class="{'question-section-manual':is_manual, 'question-section-slide':with_slide}">
      <section v-if="is_it_a_completed_activity || !isTutor" class="main-section" :class="{'main-section-slide':with_slide}">
        <h3 class="main-section__title" v-if="activity.questions.length">{{$t('activityshow.preguntas')}}</h3>
        <form @submit.prevent="saveAnswers()" class="activity-questions">
          <display-question v-for="(question, index) in activity.questions" :key="question.id" :question="question" v-model="answers[index].value"/>

          <!-- Solucion momentanea más triste del mundo :( -->
          <br><br><br><br>

          <div v-if="isTutor && activity.questions.length!=0" class="form__actions form__actions--fixed">
            <div class="form__actions-side">
              <button class="btn btn--primary" type="submit" :disabled="submitting" v-bind:class="{ disable:  !allQuestionsAnswered }">
                {{$t('activityshow.sendresp')}}
                <!-- <template v-else>{{$t('activityshow.finishacti')}}</template> -->
              </button>
            </div>
          </div>
        </form>
      </section>
    </div>
    <div class="main-section__side activity-side--info">
      <div v-if="activity.external_resource_url_apod && isTutor" class="main-section">
        <h3 class="main-section__title">{{$t('activityshow.enlaceApod')}}</h3>
        <div class="box box--sm box--no-shadow">
          <div class="resource">
            <img class="resource__icon" src="@/assets/images/icons/copy.svg" @click="copy('enlace3')">
              <a :href="activity.external_resource_url_apod" target="_blank">{{ activity.external_resource_url_apod.slice(0,30)}}...</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  </base-view-layout>

<div v-else class="nosideNav">
  <base-view-layout>
  <img class="top-nav__logo" src="https://math-for-meaning.herokuapp.com/img/LogoMeaningHorizontal.71b2c760.svg">
  <h3>Esta actividad no se encuantra disponible</h3>
  </base-view-layout>

</div>



  <modal v-if="showEndingModal" @close="showEndingModal = false">
    <template #title>
      {{$t('activityshow.respenviadas')}}
    </template>
    <template #description>
      {{$t('activityshow.respdesc')}}
    </template>
    <template #content>
      <p>{{$t('activityshow.respconfirmsend')}}</p>
    </template>
    <template #footer>
      <router-link :to="{ name: 'AccessActivity' }" class="btn btn--primary btn--outline">{{$t('activityshow.salir')}}</router-link>
      <button type="button" class="btn btn--primary" @click.prevent="showEndingModal = false">{{$t('activityshow.continueactiv')}}</button>
    </template>
  </modal>
</template>


<script>
import BaseViewLayout from '@/components/BaseViewLayout'
import DisplayQuestion from '@/components/DisplayQuestion'
import SideNav from '@/components/SideNav'
import Modal from '@/components/Modal'
import { inject, reactive} from 'vue'
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import { useRouter } from 'vue-router'

export default {
  name: 'ActivityShow',
  setup () {
    const route = useRouter().currentRoute.value
    const { activityId, code } = route.params 
    
    const axios = inject('$axios')
    const activity = reactive({ questions: [] })
    const answers = reactive([])
    let activityUrl = `activities/${activityId}`
    let questionsUrl = `activities/${activityId}/questions`
    const enlace = ''
    let existEvent = true

if (route.meta.isTutor) {
      activityUrl = `answers/activity-by-code/${code}`
      questionsUrl = `answers/questions-by-activity-code/${code}`
    }
    axios.get(activityUrl).then(res => {
      if( res.data.activity === undefined){
        existEvent = false
      }else{
         Object.keys(res.data.activity).forEach(key => activity[key] = res.data.activity[key])
      }
        
    })
    axios.get(questionsUrl).then(res => {
      res.data.questions.forEach(q => {
        answers.push({
          question_id: q.id,
          value: q.kind === 'MULTIPLE-CHOICE-MULTIPLE-OPTION' ? [] : null
        })
      })
      activity.questions = res.data.questions
    })
    
    let dispositivo = ""
    if(navigator.userAgent.toLowerCase().match(/iphone/)){
      dispositivo = "ios"
    }else if(navigator.userAgent.toLowerCase().match(/android/)){
      dispositivo = "android"
    }else if(navigator.userAgent.toLowerCase().match(/windows/)){
      dispositivo = "windows"
    }else if(navigator.userAgent.toLowerCase().match(/macintosh/)){
      dispositivo = "macintosh"
    }else{
      dispositivo = navigator.userAgent.toLowerCase()
    }
    return { activity, answers, axios, enlace,dispositivo ,existEvent}
    
  },
  data () {
    return {
      submitting: false,
      showEndingModal: false,
      localActivity: null,
      aux2:[{}],
      with_slide:null,
      is_genially:null,
      is_manual:null,
      is_it_a_completed_activity: false
    }
  },
async beforeMount () {
   const route = useRouter().currentRoute.value
    const { activityId, code } = route.params
    let instructionactivityURL = `instruction-activity/instruction-by-id/${activityId}`
    

if (route.meta.isTutor) {
      instructionactivityURL = `instruction-activity/instruction-by-code/${code}`
      
    }    


    const instruction = (await this.$axios.get(instructionactivityURL)).data.instruction[0].instruction
    
      if(instruction.includes("slide divider")){
        this.with_slide = true
        this.is_genially = false
        this.is_manual = false
        const aux = instruction.split("---slide divider---")
        for (var i = 0; i < aux.length; i++) {
          this.aux2[i] = new Object();
          this.aux2[i].name = aux[i];
      }
    }else if(instruction.includes('view.genial.ly')){
      this.with_slide = false
      this.is_genially = true
      this.is_manual = false
    }else{
      this.with_slide = false
      this.is_genially = false
      this.is_manual = true
    }
},
  methods: {
    saveAnswers () {
      this.submitting = true
      this.axios.post('answers', {
        name: this.$route.query.name,
        activity_event_id: this.activity.activity_event.id,
        answers: this.answers
      }).then(res => {
        if (res.data.answers) {
          this.showEndingModal = true
        }
      })
      
    },
    displayDeactivationModal () {
      this.showDeactivationModal = true
      this.showInformationModal = false
    },
    cancelDeactivation () {
      this.showDeactivationModal = false
      this.showInformationModal = true
    },

    copy(enlace){
      if(enlace=='enlace1'){
        navigator.clipboard.writeText(this.activity.external_resource_url_print)

      }else if(enlace=='enlace2'){
        navigator.clipboard.writeText(this.activity.external_resource_url_recom)

      }else{
        navigator.clipboard.writeText(this.activity.external_resource_url_apod)
      }
    },
    async newActivityCompleted () {
      this.is_it_a_completed_activity = true
      const element = document.getElementById("contentOfTheOpenQuestionButton");

      await this.axios.post('activitiesFinished/', {
        activity_event_id: this.activity.activity_event.id,
      })
      element.scrollIntoView(true);
    }
  },
  components: { BaseViewLayout, DisplayQuestion, SideNav, Modal,Carousel,Slide,Pagination,Navigation},
  computed: {
    myActivity () {
      const myActivity = this.localActivity || this.activity
      myActivity.grade = this.activity.grade
      myActivity.subject = this.activity.subject
      return myActivity
    },
    isCurrentlyOrIndefinitelyActive () {
      return (this.myActivity.activity_event || {}).is_active || this.myActivity.is_currently_active
    },
    activityLink () {
      return `${window.location.origin}${this.$router.getRoutes().find(r => r.name ==='AccessActivity').path}?code=${this.myActivity.activity_event.code}`
    },
    activityAuthor () {
      return `${this.myActivity.user.first_name} ${this.myActivity.user.last_name}`
    },
    isTutor () { 
      return this.$store.state.user.id === undefined 
    },
    expandedTutorSideNav: {
      
      get () {
        return this.$store.state.tutorSideNavExpanded 
      },
      set (val) {
        this.$store.commit('setTutorSideNavExpanded', this.isTutor && val)
      }
    },
    allQuestionsAnswered() {
      return this.answers.every(answer => answer.value !== null);
    }
  },
  mounted () {
    if (this.expandedTutorSideNav && !['xl', 'xxl'].includes(this.$mq)) {
      this.expandedTutorSideNav = false
    }
    if (!this.expandedTutorSideNav && ['xl', 'xxl'].includes(this.$mq)) {
      this.expandedTutorSideNav = true
    }

  }
}
</script>

<style>

.resource__icon{
   cursor:pointer !important;
}

.slide{
  background-color: var(--color-white);
  border-radius: var(--radius);
}

.main-activity-with-carrousel{
  padding: 1.5em;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
}
.content-with-carrousel{
  display: flex;
  flex-direction: column;
   
}

.carrousel{
  margin-bottom: var(--space-l);
  display: flex;
  justify-content: center;
}

.slide-content{
  padding: 2rem;
  width: 100%;
}

.side-info-with-carrousel{
  display: flex;
  justify-content: space-around;
  margin-bottom: var(--space-l);
}

.about-activity{
  width: 50%;
}
.form__actions--fixed{
  justify-content: flex-end !important;
}

.activity-grid {
    grid-template-columns: 4fr 2fr;
    grid-template-areas: "a b";
}

.activity-side--info{
  margin-bottom: var(--space-l);
}

.carrousel{
  margin-bottom: var(--space-l);
}

.slide{
  background-color: var(--color-white);
  border-radius: var(--radius);
  padding: 1rem;
  width: 50%;
}

.side-info-with-carrousel{
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--space-l);
}

.carousel{
  text-align: justify !important;
}

figure {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

@media (max-width: 428px){
  .layout .content-wrapper .main-content {
    min-height: calc(100vh - var(--space-m) * 2);
    padding: var(--space-s) var(--space-m);
  }
  .titles-header{
    margin-left: -1rem;
  }
  .breadcrumb{
    margin-left: -1rem;
  }
  .main-activity-with-carrousel{
    padding: 0rem;
  }

.carousel__prev, .carousel__next {
    background-color: var(--color-primary) !important;
    top: 95% !important;
    }

  .side-info-with-carrousel {
    flex-direction: column;
  }
  .about-activity{
    width: 100%;
     margin-bottom: var(--space-l);
  }
  .carousel__slide--prev{
    opacity: 0 !important;
  }
  .carousel__slide--next{
    opacity: 0 !important;
  }

  .carousel__slide--visible{
    padding: 1rem;
   
  }

  .carousel__prev {
    margin-inline-start: 1.5rem;
  }

  .carousel__next {
    margin-inline-end:  1.5rem;

}
.carousel__pagination-button{
    margin: 0.5px;

}

 .carousel__pagination{
  padding-inline-start: 0px;
}

.carousel{
  text-align: justify !important;
}

.slide{
  width: 100%;
}

figure {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.genially{
  display: flex !important;
  flex-direction: column !important;
  width: 100% !important;
  
}

.no-genially{
  display: flex;
  flex-direction: column;
  width: 100% !important;
}

.question-section{
  width: 100% !important;
}

.without-carrousel{
   width: 100% !important;
}

.question-section-slide .main-section-slide{
  width: 100% !important;
}

}


@media (max-width: 375px){
  .titles-header{
    margin-left: -1rem;
  }
  .breadcrumb{
    margin-left: -1rem;
  }
  .main-activity-with-carrousel{
    padding: 0rem;
  }

  .carousel__pagination {
   
    padding-inline-start: 0px !important;
  }
  .side-info-with-carrousel {
    flex-direction: column;
  }
  .about-activity{
    width: 100%;
     margin-bottom: var(--space-l);
  }

.carousel{
  text-align: justify !important;
}

.slide{
  width: 100%;
}

figure {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.genially{
  display: flex !important;
  flex-direction: column !important;
  width: 100% !important;
  
}

.no-genially{
  display: flex;
  flex-direction: column;
  width: 100% !important;
}

.question-section{
  width: 100% !important;
}

.without-carrousel{
   width: 100% !important;
}

}

  /*.activity-side--content{
  background-color: greenyellow;
}
  .main-section{
    background-color: blue;
    
  }
  .slide{
    background-color: red;
  }
*/
.carousel__prev, .carousel__next {
    background-color: var(--color-primary) !important;
    top: 100% !important;
}
.carousel__pagination-button--active {
    background-color: var(--color-primary)!important;
}
       
@media screen and (max-width: 926px) and (orientation: landscape) {
  .genially{
    align-items: center;
    width: 80% !important;
  }
  .main-section-without-carrousel-genially{
    width: 85% !important;
  }
  .form__actions.form__actions--fixed{
    background-color: transparent !important;
  }
  .top-nav{
    height: 2.5rem;
  }

  .no-genially{
  display: flex;
  flex-direction: column;
  width: 80% !important;
}
  
}

.genially{
  display: flex;
  flex-direction: column;
  width: 100%;
  
}

.no-genially{
  display: flex;
  flex-direction: column;
  width: 50%;
}

.no-title-genially{
  display: none;
}

.question-section{
  margin-top: 2rem;
  width: 60%;
}

.activity{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.activity-without-carrousel-manual{
  display: block;
  width: 100%;
}

.question-section-manual{
  width: 50%;
}

.without-carrousel-genially{
  width: 60%;
}

.without-carrousel-slide{
  display: block;
}

.question-section-slide{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.question-section-slide .main-section-slide{
  width: 60%;
}

.disable{
  width: auto;
  background-color: var(--color-primary);
  border: 1px solid var(--color-primary);
  color: var(--color-white);
  pointer-events:none;
  opacity: 0.5;
}
</style>
